<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Bloques</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Bloques</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA AL MODAL DE BLOQUE EXPORT -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_lista_bloques"
                        v-if="$store.getters.can('admin.bloques.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('admin.bloques.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Bloque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Prefijo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.prefijo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Código
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Ciudad
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.ciudad"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="ciudad in listasForms.ciudades"
                            :key="ciudad.id"
                            :value="ciudad.id"
                          >
                            {{ ciudad.nombre }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Departamento
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.departamento"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="departamento in listasForms.departamentos"
                            :key="departamento.id"
                            :value="departamento.id"
                          >
                            {{ departamento.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Dirección
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.direccion"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="bloque in bloques.data" :key="bloque.id">
                      <td>{{ bloque.nombre }}</td>
                      <td>{{ bloque.prefijo }}</td>
                      <td>{{ bloque.codigo }}</td>
                      <td>{{ bloque.ciudad.nombre }}</td>
                      <td>{{ bloque.departamento.descripcion }}</td>
                      <td>{{ bloque.direccion }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            bloque.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ bloque.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.bloques.show')"
                            @click="edit(bloque)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            :class="
                              bloque.estado == 1
                                ? 'btn btn-sm btn-warning'
                                : 'btn btn-sm btn-success'
                            "
                            data-toggle="modal"
                            data-target="#modal-form-bloque"
                            v-if="$store.getters.can('admin.bloques.show')"
                            @click="datosModal(bloque)"
                          >
                            <i
                              :class="
                                bloque.estado == 1 ? 'fas fa-lock' : 'fas fa-unlock'
                              "
                            ></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.bloques.delete')"
                            @click="destroy(bloque.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="bloques.total">
                  <p>
                    Mostrando del <b>{{ bloques.from }}</b> al <b>{{ bloques.to }}</b> de
                    un total: <b>{{ bloques.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">No hay registros para mostrar</span>
                  </p>
                </div>
                <pagination
                  :data="bloques"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <BloqueExport ref="BloqueExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import BloqueExport from "./BloqueExport";

export default {
  name: "BloqueIndex",
  components: {
    pagination,
    BloqueExport,
  },
  data() {
    return {
      filtros: {
        bloque: null,
        codigo: null,
        ciudad: null,
        departamento: null,
        direccion: null,
        estado: null,
        prefijo: null,
      },
      bloques: {},
      listasForms: {
        ciudades: [],
        departamentos: [],
        estados: [],
      },
      modal: {
        title: "",
      },
    };
  },
  validations() {
    if (this.form.estado == 1) {
      return {
        form: {
          estado: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          estado: {
            required,
          },
          justificacion: {
            required,
          },
        },
      };
    }
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/bloques?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.bloques = response.data;
        });
    },

    getCiudades() {
      axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    getDepartamentos() {
      axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    datosModal(bloque) {
      this.modal.title = bloque.nombre;
      this.form.id = bloque.id;
      this.form.estado = bloque.estado;
      this.form.justificacion = bloque.justificacion;
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios
          .put("/api/admin/bloques", this.form)
          .then((response) => {
            this.$refs.closeModal.click();
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se actualizó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idBloque) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/bloques/" + idBloque).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el item exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/BloquesForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(empresa) {
      return this.$router.push({
        name: "/Admin/BloquesForm",
        params: { accion: "Editar", data_edit: empresa, id: empresa.id },
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getCiudades();
    this.getDepartamentos();
    this.getEstados();
  },
};
</script>
